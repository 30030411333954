import { FooterStyled } from "./style";
import nutrialAracati from "../../asserts/images/nutrial-logo-a.jpg";
import nutrialLimoeiro from "../../asserts/images/nutrial-logo.jpg";
import logoTema from "../../asserts/images/LOGO TEMA.jpg";
import instagramIcon from "../../asserts/images/instagram.png";
import agroValeLogoo from "../../asserts/images/agrovale.jpg";
import repRacoes from "../../asserts/images/rep-racoes.jpg";
import fortNutri from "../../asserts/images/fortnutri-logo.jpg";

export function Footer() {
  return (
    <FooterStyled>
      <div className="footer-box">
        <div className="footer-div-1">
          <img className="img-1" src={nutrialAracati} alt="" />
        </div>
        <div className="footer-div-2">
          <p>
            Nutrial Aracati <br /> Rua coronel Alexandrino, 1363
            Centro - Aracati-CE
            <br /> Telefone: (88) 99696-3000
          </p>
          <hr />
          <a
            className="insta-icon"
            target="_blank"
            href="https://www.instagram.com/nutrialracoes"
          >
            <img src={instagramIcon} alt="" />
          </a>
        </div>
      </div>
      <div className="footer-box">
        <div className="footer-div-1">
          <img src={nutrialLimoeiro} alt="" />
        </div>
        <div className="footer-div-2">
          <p>
            Nutrial Limoeiro do Norte <br /> Rod. Presidente Geisel, 565 Bom
            Jesus - Limoeiro do Norte
            <br /> Telefone: (88) 99905-0051
          </p>
          <hr />
          <a
            className="insta-icon"
            target="_blank"
            href="https://www.instagram.com/nutrialracoes"
          >
            <img src={instagramIcon} alt="" />
          </a>
        </div>
      </div>
      <div className="footer-box">
        <div className="footer-div-1">
          <img src={logoTema} alt="" />
        </div>
        <div className="footer-div-2">
          <p>
            Tema Carcinicultura <br /> Rua Cel Clovis Alexandrino 1820,
            Brotolãndia
            <br /> Telefone: (88) 99770-0120
            <br /> Email: anaerika@grupocultivar.agr.br
          </p>
          <hr />
          <a
            className="insta-icon"
            target="_blank"
            href="https://www.instagram.com/grupocultivar"
          >
            <img src={instagramIcon} alt="" />
          </a>
        </div>
      </div>
      <div className="footer-box">
        <div className="footer-div-1">
          <img src={agroValeLogoo} alt="" />
        </div>
        <div className="footer-div-2">
          <p>
            AGROVALE: Rua Manoel Saraiva, 646, Limoeiro do Norte - CE
            <br /> Telefones: (88) 3423-4040 / (88) 9 99270183
            <br /> Email: sac@agrovalelimoeiro.com.br
            <br />
          </p>
          <hr />
          <a
            className="insta-icon"
            target="_blank"
            href="https://www.instagram.com/agrovalelimoeiro"
          >
            <img src={instagramIcon} alt="" />
          </a>
        </div>
      </div>
      <div className="footer-box">
        <div className="footer-div-1">
          <img src={repRacoes} alt="" />
        </div>
        <div className="footer-div-2">
          <p>
            REP RAÇÕES: Rua São José, 1092, Jaguaruana - CE
            <br /> Telefone: (88) 99364-1723
            <br /> Email: repracoesjaguaruana@gmail.com.br
          </p>
          <hr />
        </div>
      </div>
      <div className="footer-box">
        <div className="footer-div-1">
          <img src={fortNutri} alt="" />
        </div>
        <div className="footer-div-2">
          <p>
            Vila Mota - S/N Guajiru - Fortim -CE
            <br /> Telefone: (88) 99232-2702
            <br /> Email: fortnutriracoes@gmail.com
          </p>
          <hr />
          <a
            className="insta-icon"
            target="_blank"
            href="https://www.instagram.com/fortnutriracoes"
          >
            <img src={instagramIcon} alt="" />
          </a>
        </div>
      </div>
    </FooterStyled>
  );
}
